'use client';
import React from 'react';

import { cn } from '@/lib/utils';

import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { FormEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Card, CardDescription, CardHeader } from '@/components/ui/card';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Form({ className, ...props }: UserAuthFormProps) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const router = useRouter();
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage(''); // Limpar mensagem de erro anterior
        const formData = new FormData(e.currentTarget);
        const response = await signIn('credentials', {
            email: formData.get('email'),
            password: formData.get('password'),
            redirect: false,
        });

        if (response) {
            if (response.error) {
                setErrorMessage(response.error);
            } else if (response.ok) {
                router.push('/dashboard');
                router.refresh();
            } else {
                setErrorMessage('Erro desconhecido. Tente novamente.');
            }
        } else {
            setErrorMessage(
                'Falha na resposta da autenticação. Tente novamente.'
            );
        }
        setIsLoading(false);
    };
    return (
        <Card className="mx-auto max-w-sm bg-white p-5 w-screen">
            <CardHeader className="text-center">
                <div className="flex flex-col space-y-2 text-center items-center">
                    <div className="relative z-20 flex items-center text-lg text-black  font-bold">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 17.456C11.2389 17.8053 10.3922 18 9.5 18C6.18629 18 3.5 15.3137 3.5 12C3.5 8.68629 6.18629 6 9.5 6C10.3922 6 11.2389 6.19474 12 6.54404C12.7611 6.19474 13.6078 6 14.5 6C17.8137 6 20.5 8.68629 20.5 12C20.5 15.3137 17.8137 18 14.5 18C13.6078 18 12.7611 17.8053 12 17.456ZM10.0003 15.969C9.83637 15.9895 9.66942 16 9.5 16C7.29086 16 5.5 14.2091 5.5 12C5.5 9.79086 7.29086 8 9.5 8C9.66942 8 9.83637 8.01053 10.0003 8.03098C9.06656 9.08871 8.5 10.4782 8.5 12C8.5 13.5218 9.06656 14.9113 10.0003 15.969ZM13.9998 8.03098C14.1636 8.01054 14.3306 8 14.5 8C16.7091 8 18.5 9.79086 18.5 12C18.5 14.2091 16.7091 16 14.5 16C14.3306 16 14.1636 15.9895 13.9998 15.969C14.9334 14.9113 15.5 13.5218 15.5 12C15.5 10.4782 14.9334 9.08872 13.9998 8.03098ZM12 8.87732C12.9145 9.61038 13.5 10.7368 13.5 12C13.5 13.2632 12.9145 14.3896 12 15.1227C11.0855 14.3896 10.5 13.2632 10.5 12C10.5 10.7368 11.0855 9.61038 12 8.87732Z"
                                fill="currentColor"
                            />
                        </svg>
                        Church Tech
                    </div>
                    <Image
                        className="pb-3"
                        src="/assets/img/logobethel.png"
                        alt="Bethel Assembly"
                        width={200}
                        height={200}
                    />
                </div>
                <CardDescription>
                    Insira suas credênciais para acessar o sistema
                </CardDescription>
            </CardHeader>

            <div className={cn('grid gap-6', className)} {...props}>
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-4">
                        <div className="grid gap-2">
                            <Label className="sr-only" htmlFor="email">
                                Email
                            </Label>
                            <Input
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                type="email"
                                autoCapitalize="none"
                                autoComplete="email"
                                autoCorrect="off"
                                disabled={isLoading}
                            />
                        </div>
                        <div className="grid gap-1">
                            <Label className="sr-only" htmlFor="email">
                                Senha
                            </Label>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Senha"
                                type="password"
                                autoCapitalize="none"
                                autoComplete="password"
                                autoCorrect="off"
                                disabled={isLoading}
                            />
                        </div>
                        {errorMessage && (
                            <div className="bg-red-400 text-white text-xs p-1 rounded-md text-center">
                                <p>{errorMessage}</p>
                            </div>
                        )}
                        <Button disabled={isLoading}>
                            {isLoading && (
                                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Entrar
                        </Button>
                    </div>
                </form>
            </div>
            <div className="mt-2 px-1 text-center text-sm text-muted-foreground flex justify-between">
                <Link
                    href="/forgot-password"
                    className=" underline-offset-4 hover:text-primary hover:underline text-left"
                >
                    Esqueceu sua senha?
                </Link>
                <Link
                    href="/signup"
                    className=" underline-offset-4 hover:text-primary hover:underline"
                >
                    Cadastre-se
                </Link>
            </div>
            <div className="flex items-center justify-center mt-5">
                <blockquote className=" w-80 space-y-2 p-5">
                    <p className="text-sm lg:text-sm italic text-gray-600">
                        &ldquo;Em nossas vidas o mais importante é construir os
                        nossos relacionamento começando por Deus, com a nossa
                        familia e a nossa comunidade.&rdquo;
                    </p>
                    <footer className="text-xs text-black italic">
                        - Bethel Assembly
                    </footer>
                </blockquote>
            </div>
        </Card>
    );
}
